import React, {useState} from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import {graphql, Link, StaticQuery} from 'gatsby'
import ReactMarkdown from "react-markdown";

const MailchimpForm = ({dc}) => {

    const [fname, setFName] = useState('')
    const [lname, setLName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [zip, setZip] = useState('')
    const [adress, setAdress] = useState('')
    const [country, setCountry] = useState('')
    const [campaign, setCampaign] = useState('')
    const [dogname, setDogname] = useState('')
    const [breed, setBreed] = useState('')
    const [dogage, setDogage] = useState('')
    const [dogweight, setDogweight] = useState('')
    const [contactper, setContactper] = useState('')
    const [checkbox, setCheckbox] = useState(true)

    const handleSubmit = (e) => {
        e.preventDefault();

        addToMailchimp(email, {
            FNAME: fname,
            LNAME: lname,
            PHONE: phone,
            ZIP: zip,
            ADRESS: adress,
            COUNTRY: country,
            CAMPAIGN: campaign,
            DOGNAME: dogname,
            BREED: breed,
            DOGAGE: dogage,
            DOGWEIGHT: dogweight,
            CONTACTPER: contactper,
            checkbox: checkbox,
        })
            .then(({msg}) => {
               alert(msg);
            })
            .catch((err) => {
                alert(err);
            });

        document.getElementById('mcForm').style.display = 'none'
        document.getElementById('MCsuccessMessage').style.display = 'block'
    }


    return (

        <div className="contact-form pb-100">
            <form id={'mcForm'} onSubmit={handleSubmit} className="text-center subscribe">

                <div className="form-group mc-group">
                <input
                    type="text"
                    name="fname"
                    className="form-control mc-input"
                    required
                    placeholder={dc.first_name_placeholder}
                    onChange={event => setFName(event.target.value)}
                />

                    <input
                        onChange={event => setLName(event.target.value)}
                        type="text"
                        name="lname"
                        className="form-control mc-input"
                        required
                        placeholder={dc.last_name_placeholder}
                    />
                </div>


                <div className="form-group mc-group">
                    <input
                        onChange={event => setEmail(event.target.value) + setCampaign(`${dc.campaign_name}`) + setCountry(`${dc.country}`) }
                        type="email"
                        name="email"
                        required
                        className="form-control mc-input"
                        placeholder={dc.email_placeholder}
                    />

                    <input
                        onChange={event => setPhone(event.target.value)}
                        type="text"
                        name="phone"
                        required
                        className="form-control mc-input"
                        placeholder={dc.tel_placeholder}
                    />

                </div>

                <div className="form-group mc-group">
                    <input
                        onChange={event => setZip(event.target.value)}
                        type="text"
                        name="zip"
                        required
                        className="form-control mc-input"
                        placeholder={dc.zip_code_placeholder}
                    />

                    <input
                        onChange={event => setAdress(event.target.value)}
                        type="text"
                        name="adress"
                        className="form-control mc-input"
                        placeholder={dc.adress_placeholder}
                    />

                </div>

                <hr className={'mc-form-line'} />

                <div className="form-group mc-group">
                    <input
                        onChange={event => setDogname(event.target.value)}
                        type="text"
                        name="dogname"
                        required
                        className="form-control mc-input"
                        placeholder={dc.dog_name_placeholder}
                    />

                    <input
                        onChange={event => setBreed(event.target.value)}
                        type="text"
                        name="breed"
                        required
                        className="form-control mc-input"
                        placeholder={dc.dog_breed_placeholder}
                    />

                </div>



                <div className="form-group mc-group">
                    <input
                        onChange={event => setDogage(event.target.value)}
                        type="text"
                        name="dogage"
                        className="form-control mc-input"
                        placeholder={dc.dog_age_placeholder}
                    />

                    <input
                        onChange={event => setDogweight(event.target.value)}
                        type="text"
                        name="dogweight"
                        className="form-control mc-input"
                        placeholder={dc.dog_weight_placeholder}
                    />

                </div>

                <div className="form-group mc-group">
                    <p className={'mc-formtext'}>{dc.pre_dropdown_text}</p>

                    <select
                        onChange={event => setContactper(event.target.value)}
                        name="dropdown"
                        className="form-control mc-input"
                    >
                        {dc.dropdown_value?.map((dd, idx)=> {
                            return(
                                <option key={idx} value={dd.dropdown_text} onChange={event => setContactper(event.target.value)} >{dd.dropdown_text}</option>
                            )

                        })}
                    </select>

                    <p className={'mc-formtext'}>{dc.after_dropdown_text}</p>
                </div>


                <input className={'hidden-field'}
                    type="text"
                    name="campaign"
                    placeholder="Campaign Name"
                />

                <input className={'hidden-field'}
                       type="text"
                       name="country"
                       placeholder="Country"
                />

                <div className="form-group mc-checkbox-group">
                    <input type={'checkbox'} value={checkbox} name="checkbox"
                           onChange={event => setCheckbox(event.target.value)}
                           className="checkbox" required />
                    <p>{dc.checkbox_text}<Link to={`/${dc.checkbox_legal.slug}`}>{dc.checkbox_linktext}</Link></p>
                </div>

                <button type="submit" color="primary">
                    <i className="flaticon-right"></i>{dc.button_text}
                </button>
            </form>

            <div id={'MCsuccessMessage'} className='successMessage'>
                <ReactMarkdown children={dc.success_message} />
            </div>
        </div>

    )

}


export default MailchimpForm
